<template>
    <div>
    
        <!-- <v-row>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalUnpaidMerchantAmount) }} د.ع</h3>
                    <h4 class="mt-2">مجموع مستحقات التجار الغير مدفوعة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalPaidMerchantAmount) }} د.ع</h3>
                    <h4 class="mt-2">مجموع مستحقات التجار المدفوعة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalUnpaidDelegaterAmount) }} د.ع</h3>
                    <h4 class="mt-2">مجموع مستحقات المندوبين الغير مدفوعة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalPaidDelegaterAmount) }} د.ع</h3>
                    <h4 class="mt-2">مجموع مستحقات المندوبين المدفوعة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalUnpaidDelegaterAmountDebits) }} د.ع</h3>
                    <h4 class="mt-2">مجموع ذمم المندوبين الغير مستلمة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom">
                    <h3>{{ $service.formatCurrency(totals.totalPaidDelegaterAmountDebits) }} د.ع</h3>
                    <h4 class="mt-2">مجموع ذمم المندوبين المستلمة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom" color="teal">
                    <h3>{{ $service.formatCurrency(totals.totalPaidAmount) }} د.ع</h3>
                    <h4 class="mt-2">اجمالي المستحقات المدفوعة</h4>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3">
                <v-alert text class="text-center" border="bottom" color="error">
                    <h3>{{ $service.formatCurrency(totals.totalUnPaidAmount) }} د.ع</h3>
                    <h4 class="mt-2">اجمالي المستحقات الغير مدفوعة</h4>
                </v-alert>
            </v-col>
          
        </v-row> -->
    
        <v-row align="center" class=" mb-3">
            <!-- <v-col cols="12" md="2" >
                <v-btn @click="openAddDialog()" color="primary" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة {{this.$route.meta.single}}</h3>
                </v-btn>
            </v-col> -->
            <v-spacer />
            <v-col cols="12" md="3">
                <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث حسب الاسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="orderNo" label="بحث حسب رقم الشحنة" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
            </v-col>
        </v-row>
     
        <v-card outlined>
            <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
                <template v-slot:item.user.fullName="{item}">
                    <div>{{ item.user.fullName }} ( {{ item.user.userType.name }} )</div>
                </template>
                <template v-slot:item.amount="{item}">
                    <div>{{ $service.formatCurrency(item.amount) }}</div>
                </template>
                <template v-slot:item.paidAmount="{item}">
                    <div>{{ $service.formatCurrency(item.paidAmount) }}</div>
                </template>
                <template v-slot:item.toPay="{item}">
                    <div>{{ $service.formatCurrency(item.amount - item.paidAmount) }}</div>
                </template>
                <template v-slot:item.description="{item}">
                    <div>مبلغ الشحنة رقم: {{ item.description }} (  <a href="">{{ item.order.no }}#</a>  )</div>
                </template>
                <template v-slot:item.payDate="{item}">
                    <div v-if="item.payDate != '0001-01-01T00:00:00'">
                        {{item.payDate.substr(0,10)}}
                    </div>
                    --:---:---
                </template>
                <template v-slot:item.transactionType="{item}">
                    <v-chip v-if="item.transactionType == 1" color="error" dark> ذمة </v-chip>
                    <v-chip v-if="item.transactionType == 0" color="indigo" dark> استحقاق </v-chip>
                </template>
                <template v-slot:item.isPaid="{item}">
                    <v-chip v-if="item.isPaid == 0" color="orange" dark> معلق </v-chip>
                    <v-chip v-if="item.isPaid == 1" color="indigo" dark> {{ item.transactionType == 0 ? 'مسدد' : 'مستلم' }} جزئي </v-chip>
                    <v-chip v-if="item.isPaid == 2" color="teal" dark> {{ item.transactionType == 0 ? 'مسدد' : 'مستلم' }} كامل </v-chip>
                </template>
                <template v-slot:item.actions="{item}">
    
                    <!-- <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x == `${$route.path.slice(1)}-delete`) || $store.state.user.userType == $global.state.roleType.admin">
                        <template v-slot:activator="{ on }">
                            <v-btn @click="deleteItem(item.id)" v-on="on" icon>
                                <v-icon color="error">
                                    fi fi-rr-trash
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip>
    
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-btn @click="openEditDialog(item)" v-on="on" icon>
                                <v-icon color="info">
                                    fi fi-rr-edit
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>تعديل</span>
                    </v-tooltip> -->
    
                    <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-pay`) || $store.state.user.userType.id == $global.state.roleType.admin">
                        <template v-slot:activator="{ on }">
                            <v-btn @click="openPaymentDialog(item)" v-on="on" :color="item.transactionType == 0 ? 'indigo' : 'teal'" block dark class="btn">
                                <v-icon class="ml-2">
                                    {{ item.transactionType == 0 ? 'fi fi-rr-wallet' : 'fi fi-rr-donate' }} 
                                </v-icon>
                                <h4>{{ item.transactionType == 0 ? 'تسديد' : 'استلام' }}</h4>
                                </v-btn>
                        </template>
                        <span>
                            {{ item.transactionType == 0 ? 'تسديد' : 'استلام' }}
                        </span>
                    </v-tooltip>
                    
    
                </template>
            </v-data-table>
        </v-card>
        <Pagination />
        <Dialog />
    </div>
    </template>
    
    <script>
    export default {
    
        components: {
            Dialog: () => import("./dialog"),
        },
    
        data() {
            return {
                loading: false,
                searchName:"",
                orderNo:"",
                governorateId:"",
                totals: {
                    "totalUnpaidMerchantAmount": 0,
                    "totalPaidMerchantAmount": 0,
                    "totalUnpaidDelegaterAmount": 0,
                    "totalPaidDelegaterAmount": 0,
                    "totalUnpaidDelegaterAmountDebits": 0,
                    "totalPaidDelegaterAmountDebits": 0,
                    "totalPaidAmount": 0,
                    "totalUnPaidAmount": 0
                },
                headers: [
                    {
                        text: "الاسم",
                        value: "user.fullName"
                    },
                    {
                        text: "الوصف",
                        value: "description"
                    },
                    {
                        text: "المبلغ",
                        value: "amount"
                    },
                    {
                        text: "النوع",
                        value: "transactionType"
                    },
                    {
                        text: "المبلغ (المستلم / المسدد)",
                        value: "paidAmount"
                    },
                    {
                        text: "المبلغ المتبقي",
                        value: "toPay"
                    },
                    {
                        text: "تاريخ (الاستلام / الدفع)",
                        value: "payDate"
                    },
                    {
                        text: "الحالة",
                        value: "isPaid"
                    },
                    // {
                    //     text: "الاجراءات",
                    //     value: "actions"
                    // }
                ],
    
                items: []
            }
        },
    
        created() {
            this.getItems()
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        },
    
        beforeDestroy() {
            this.$eventBus.$off(`${this.$route.path}`)
        },
    
        methods: {
    
            async getItems() {
                this.loading = true
                try {
                    let res = await this.$http.get(`paymentTransaction?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.searchName}&orderNo=${this.orderNo}`)
                    this.items = res.data.result
                    this.$global.state.count = res.data.count
                    this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
                    this.totals = res.data.totalCounts
                } catch (err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            },
    
            async deleteItem(id) {
                this.$genericService.swalAlertConfirm({}).then((result) => {
                    if (result.isConfirmed) {
                        this.$genericService
                            .delete(`${this.$route.meta.endPoint}`, id)
                            .then((res) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `تم الحذف بنجاح`,
                                    type: "success",
                                });
                                this.getItems();
                            })
                            .catch((err) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `حصل خطا اثناء الحذف`,
                                    type: "error",
                                });
                            });
                    }
                });
            },
    
            openAddDialog() {
                var item = {
                    dialogType: 'add'
                }
                this.$store.commit('setItemDetails', item)
                this.$store.commit('setDialog')
            },
    
            openEditDialog(item) {
                item.dialogType = 'edit'
                this.$store.commit('setItemDetails', item)
                this.$store.commit('setDialog')
                this.$eventBus.$emit('fill-fields')
            },
    
            openPaymentDialog(item) {
                item.dialogType = 'edit'
                this.$store.commit('setItemDetails', item)
                this.$store.commit('setDialog2')
                this.$eventBus.$emit('fill-fields')
            },
        },
    
    }
    </script>
    